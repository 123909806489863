/**
 * create by wangchunyan1 on 2019/6/14
 */
import React from "react";
import "./advantage.less";

class Advantage extends React.Component{
  moveEnter(e) {
    if (!e.target.classList.contains('innerHoverBox')) {
      return;
    }
    e.target.classList.remove('cardAnimationEnd');
    e.target.classList.add('cardAnimationStart');
  }

  moveOut(e) {
    if (!e.target.classList.contains('innerHoverBox')) {
      return;
    }
    e.target.classList.remove('cardAnimationStart');
    e.target.classList.add('cardAnimationEnd');
  }

  render() {
    return (
      <div className='advantageWrapBox'>
        <div className='advantageBox'>
          <h3 className='commonTitleH3'>核心优势显著</h3>
          <h4 className='commonTitleH4'>
            简单智课堂在重构学校较重流程中，不断更新精英教师资源，不断改进经验，完善题库。根据学<br/>校的应用模式和十几环境配置，调整教学应交部署，并完善教学科研支持服务，逐渐形成智<br/>课堂四大核心优势，大大提升其竞争力。
          </h4>
          <div className='advantageContainer'>
            <div className='advantageList'>
              <div className='hoverBox'>
                <div className='innerHoverBox' onMouseEnter={this.moveEnter.bind(this)} onMouseLeave={this.moveOut.bind(this)}>
                  <dl className='advantageItem'>
                    <dt>
                      <label className='mingshiweike'/>
                    </dt>
                    <dd>
                      <h4>成体系精品微课</h4>
                      <p>约20万节，课程覆盖9个学科，3级难度。</p>
                    </dd>
                  </dl>
                </div>
              </div>

              <div className='hoverBox'>
                <div className='innerHoverBox' onMouseEnter={this.moveEnter.bind(this)} onMouseLeave={this.moveOut.bind(this)}>
                  <dl className='advantageItem'>
                    <dt>
                      <label className='tiku'/>
                    </dt>
                    <dd>
                      <h4>结构化题库</h4>
                      <p>例题同类题精准配套。</p>
                    </dd>
                  </dl>
                </div>
              </div>

              <div className='hoverBox'>
                <div className='innerHoverBox' onMouseEnter={this.moveEnter.bind(this)} onMouseLeave={this.moveOut.bind(this)}>
                  <dl className='advantageItem'>
                    <dt>
                      <label className='shuzipingtai'/>
                    </dt>
                    <dd>
                      <h4>精准数字平台</h4>
                      <p>学生端、教师端软件、学生端psd，一个区域微云服务器系统。</p>
                    </dd>
                  </dl>
                </div>
              </div>
              <div className='hoverBox'>
                <div className='innerHoverBox' onMouseEnter={this.moveEnter.bind(this)} onMouseLeave={this.moveOut.bind(this)}>
                  <dl className='advantageItem'>
                    <dt>
                      <label className='jiaoyanfuwu'/>
                    </dt>
                    <dd>
                      <h4>持续教研服务</h4>
                      <p>保持日常沟通，随时电话咨询指导。</p>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Advantage;
