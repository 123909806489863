/**
 * create by wangchunyan1 on 2019/6/22
 */
import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Banner from "../components/banner/banner";
import VideoBox from "../components/videoBox/videoBox";
import Policy from "../components/policy/policy";
import Advantage from "../components/advantage/advantage";
import ElegantDemeanor from "../components/elegantDemeanor/elegantDemeanor";
import Service from "../components/service/service";
import News from "../components/news/news";
import About from "../components/about/about";
import Tips from "../components/tips/tips";
import ContactUs from "../components/contactUs/contactUs";

import './homep.less'

class HomeP extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data: props.data.allWordpressPost.edges, width: 0};
  }

  render() {
    return (
      <Layout>
        <Banner/>
        <VideoBox/>
        <Advantage/>
        <ElegantDemeanor/>
        <Service/>
        <News data={this.state.data}/>
        <About/>
        <Tips/>
        <ContactUs/>
      </Layout>
    );
  }
}
export default HomeP;


export const indexQueryP = graphql`
query indexQueryP {
  allWordpressPost(sort: {fields: modified, order: DESC},limit: 3) {
    edges {
      node {
        id
        title
        excerpt
        link
        featured_media {
        source_url
      }
      }
    }
  }
}
`;
