/**
 * create by wangchunyan1 on 2019/6/14
 */
import React from "react";
import Message from '../message/message';
import "./banner.less";
import iconArrowRight from '../../images/icon_arrowRight2.png'

class Banner extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      bannerIndex: 0,
      isShowMessage:false
    };
    this.carouselUlRef = React.createRef();
    this.carouselIdxRef = React.createRef();
    this.child = [];
    this.childIdx = [];
    this.currentIdx = 0;
  }

  timer = null;

  componentDidMount() {
    this.init();
    this.start();
  }

  componentWillMount() {
    clearInterval(this.timer);
  }

  knowDetail() {
    const host = window.location.origin;
    const urlMap = {
      0: `${host}/teacherp/`,
      1: `${host}/masterp/`,
      2: ``
    };
    const url = urlMap[(this.currentIdx - 1) % this.childIdx.length];
    if (url) {
      window.location.href = url;
    } else {
      this.setState({
        isShowMessage:true
      });
      setTimeout(()=>{
        this.setState({
          isShowMessage:false
        })
      },3000);
    }
  }

  changeBanner(index){
    index = index + 1;
    this.currentIdx = index;
    this.change();
    clearInterval(this.timer);
    this.start();
  }

  /**
   * 初始化轮播图
   */
  init() {
    const carouselUlRef = this.carouselUlRef.current;
    const carouselIdxRef = this.carouselIdxRef.current;
    this.child = Array.from(carouselUlRef.children);
    this.childIdx = Array.from(carouselIdxRef.children);
    let childLen = this.child.length;
    if (!this.child.length) {
      return;
    }
    carouselUlRef.insertBefore(this.child[childLen - 1].cloneNode(true), this.child[0]);
    carouselUlRef.appendChild(this.child[0].cloneNode(true));
    this.child = Array.from(carouselUlRef.children);
    this.initIdx(this.currentIdx);
    this.currentIdx++;
    this.rowPic(this.currentIdx);
    this.child[this.child.length - 1].addEventListener('transitionend', () => {
      if (this.currentIdx >= this.child.length - 1) {
        this.currentIdx = 1;
        this.rowPic(this.currentIdx);
        for (let i = 0, len = this.child.length; i < len; i++) {
          this.child[i].style.transition = `none`;
        }
      }
    });
  }

  /**
   * 排列图片
   */
  rowPic(currentIdx) {
    for (let i = 0, len = this.child.length; i < len; i++) {
      this.child[i].style.transition = 'all ease .8s';
      this.child[i].style.transform = `translateX(${(i - currentIdx) * 100}%)`;
    }
  }

  /**
   * 初始化索引列表
   * @param currentIdx
   */

  initIdx(currentIdx) {
    for (let i = 0, len = this.childIdx.length; i < len; i++) {
      this.childIdx[i].classList.remove('active');
    }
    this.childIdx[currentIdx].classList.add('active');
  }

  start() {
    this.timer = setInterval(() => {
      this.currentIdx++;
      this.change();
    }, 3000);
  }

  change() {
    this.rowPic(this.currentIdx);
    this.initIdx((this.currentIdx -1) % this.childIdx.length );
  }

  render() {
    return (
      <div className={`bannerBox`}>
        <ul className='carousel' ref={this.carouselUlRef} >
          <li className='carouselItem bannerBox bannerBox0' />
          <li className='carouselItem bannerBox bannerBox1' />
          <li className='carouselItem bannerBox bannerBox2' />
        </ul>
        <div className='innerBox'>
          <span className='knowDetail' onClick={this.knowDetail.bind(this)}>
            了解详情<img className='rightArrow' alt='' src={iconArrowRight} />
          </span>
          <ul className='focusBox' ref={this.carouselIdxRef}>
            <li className={`teacher`} onClick={this.changeBanner.bind(this,0)}/>
            <li className={`master`} onClick={this.changeBanner.bind(this,1)}/>
            <li className={`agent`} onClick={this.changeBanner.bind(this,2)}/>
          </ul>
        </div>
        {
          this.state.isShowMessage && <Message info='内容正在维护，敬请期待！'/>
        }
      </div>
    );
  }
}

export default Banner;
