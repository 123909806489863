/**
 * create by wangchunyan1 on 2019/6/14
 */
import { Link } from "gatsby";
import React from "react";
import "./news.less";

class News extends React.Component{
  constructor(props) {
    super(props);
    this.state = {};
  }

  imgScaleStart(e) {
    e.target.classList.remove('imgAnimationEnd');
    e.target.classList.add('imgAnimationStart');
  }

  imgScaleEnd(e) {
    e.target.classList.remove('imgAnimationStart');
    e.target.classList.add('imgAnimationEnd');
  }

  render() {
    const data = this.props.data;
    return (
      <div className="newBox">
        <h3 className='commonTitleH3'>品牌动态</h3>
        <p className='commonTitleP'>学习变简单，教育更公平</p>
        <div className="newsList">
          {
            data && data.map((item) => {
              let imgUrl = (item.node.featured_media && item.node.featured_media.source_url) || "";
              return (
                <dl key={item.node.id}>
                  <a href={item.node.link} target='_blank' rel="noopener noreferrer">
                    <dt className='imgBox'>
                      <img onMouseEnter={this.imgScaleStart.bind(this)}
                           onMouseLeave={this.imgScaleEnd.bind(this)}
                           className='image1' src={imgUrl} alt=''/>
                    </dt>
                    <dd>
                      <h5><div dangerouslySetInnerHTML={{ __html: item.node.title }}/></h5>
                      <div>
                        <div dangerouslySetInnerHTML={{ __html: item.node.excerpt }}/>
                      </div>
                    </dd>
                  </a>
                </dl>
              );
            })
          }
        </div>
        <Link to='/newsp/'><span className="moreNews">更多新闻&nbsp;&gt;</span></Link>
      </div>
    );
  }
}
export default News;
