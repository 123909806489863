/**
 * create by wangchunyan1 on 2019/6/14
 */
// import { Link } from "gatsby";
import React from "react";
import "./about.less";

import picData1 from '../../images/pic_aboutData1.jpg'
import picData2 from '../../images/pic_aboutData2.png'
import picData3 from '../../images/pic_aboutData3.jpg'

const About = () => {
  return (
    <div className="aboutBox">
      <h3 className='commonTitleH3'>关于简单科技</h3>
      <p className='commonTitleP '>学习变简单，教育更公平</p>
      <div className="aboutList">
        <dl>
          <dt>
              <img alt='' src={picData1}/>
          </dt>
          <dd>成立于2007年，创始团队主要来自北京大学，斯坦福大学，核心技术和北京大学共同申请的国家专利技术。</dd>
        </dl>
        <dl>
          <dt><img alt='' src={picData2}/></dt>
          <dd>14年来，可累计了20万节精品微课，发展了700多家学习中心。</dd>
        </dl>
        <dl>
          <dt><img alt='' src={picData3}/></dt>
          <dd>简单科技旗下品牌简单学习网累计注册学员2400万。</dd>
        </dl>
      </div>
    </div>
  );
};
export default About;
