/**
 * create by wangchunyan1 on 2019/6/14
 */
// import { Link } from "gatsby";
import React from "react";
import "./elegantDemeanor.less";

import picData1 from '../../images/pic_data1.png'
import picData2 from '../../images/pic_data2.png'
import picData3 from '../../images/pic_data3.png'

class ElegantDemeanor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  imgScaleStart(e) {
    e.target.classList.remove('imgAnimationEnd');
    e.target.classList.add('imgAnimationStart');
  }

  imgScaleEnd(e) {
    e.target.classList.remove('imgAnimationStart');
    e.target.classList.add('imgAnimationEnd');
  }

  render() {
    return(
      <div className='elegantDemeanor'>
        <h3 className='commonTitleH3'>使用风采展示</h3>
        <h4 className='commonTitleH4'>
          十三个省，数十家基地示范校，数百万教师和学生正在使用“简单智课堂”，老师单校单周<br/>使用频次大7.58次，学生单科单周上课完成题目。
        </h4>
        <ul className='elegantDemeanorData'>
          <li>
            <dl>
              <dt><img alt='' src={picData1} /></dt>
              <dd>13个省、<br/>数十家基地示范校。</dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt><img alt='' src={picData2} /></dt>
              <dd>老师单校单周<br/>使用频次达7.58次。</dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt><img alt='' src={picData3} /></dt>
              <dd>学生单科单周<br/>课上完成题目数达48道。</dd>
            </dl>
          </li>
        </ul>
        <div>
          <div className="elegantDemeanorImages">
            <div>
              <label onMouseEnter={this.imgScaleStart.bind(this)}
                     onMouseLeave={this.imgScaleEnd.bind(this)}
                     className='image1'/>
            </div>
            <div>
              <label onMouseEnter={this.imgScaleStart.bind(this)}
                     onMouseLeave={this.imgScaleEnd.bind(this)}
                     className='image2'/>
            </div>
            <div>
              <label onMouseEnter={this.imgScaleStart.bind(this)}
                     onMouseLeave={this.imgScaleEnd.bind(this)}
                     className='image3'/>
            </div>
          </div>
          <div className="elegantDemeanorImages">
            <div>
              <label onMouseEnter={this.imgScaleStart.bind(this)}
                     onMouseLeave={this.imgScaleEnd.bind(this)}
                     className='image4'/>
            </div>
            <div>
              <label onMouseEnter={this.imgScaleStart.bind(this)}
                     onMouseLeave={this.imgScaleEnd.bind(this)}
                     className='image5'/>
            </div>
            <div>
              <label onMouseEnter={this.imgScaleStart.bind(this)}
                     onMouseLeave={this.imgScaleEnd.bind(this)}
                     className='image6'/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ElegantDemeanor;
