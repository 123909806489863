/**
 * create by wangchunyan1 on 2019/6/14
 */
import React from "react";
import "./service.less";

class Service extends React.Component{

  imgScaleStart(e) {
    e.target.classList.remove('imgAnimationEnd');
    e.target.classList.add('imgAnimationStart');
  }

  imgScaleEnd(e) {
    e.target.classList.remove('imgAnimationStart');
    e.target.classList.add('imgAnimationEnd');
  }

  render() {
    return (
      <div className="serviceBox">
        <h3 className='commonTitleH3'>提供全程支持服务<br/>
          定期分享使用案例</h3>
        <h4 className='commonTitleH4'>
          简单智课堂拥有一套持续的教学教研支持服务，老师们在使用过程中遇到任何需求或问题，教学支持会快<br/>速反馈，协助解决。定期邀请优秀使用老师分享个人经验，共同进步。
        </h4>
        <div className="serviceImages">
          <div>
            <label onMouseEnter={this.imgScaleStart.bind(this)}
                   onMouseLeave={this.imgScaleEnd.bind(this)}
                   className='image1'/>
          </div>
          <div>
            <label onMouseEnter={this.imgScaleStart.bind(this)}
                   onMouseLeave={this.imgScaleEnd.bind(this)}
                   className='image2'/>
          </div>
          <div>
            <label onMouseEnter={this.imgScaleStart.bind(this)}
                   onMouseLeave={this.imgScaleEnd.bind(this)}
                   className='image3'/>
          </div>
        </div>
      </div>
    );
  }
}
export default Service;
