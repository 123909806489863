/**
 * create by wangchunyan1 on 2019/6/14
 */
import React from "react";
import "./videoBox.less";
import poster from "../../images/pic_videoPoster.jpg";
import {Video} from '../../utils/constant'

class VideoBox extends React.Component{
  constructor(props) {
    super(props);
    this.state = {};
  }
  play(e) {
    if (!e.target) {
      return;
    }
    e.preventDefault();
    const video = e.target;
    if (video.nodeName.toLowerCase() === 'video') {
      if (video.paused) {
        video.play();
      } else {
        video.pause();
      }
    }
  }
  render() {
    return (
      <div className='videoBox'>
        <h3 className='commonTitleH3'>智课堂视频介绍</h3>
        <p className='commonTitleP'>学习变简单，教育更公平</p>
        <div className='videoContainer'>
          <video className='video' controls controlsList='nodownload' poster={poster} onClick={this.play.bind(this)} loop>
            <source
              src={Video.Trailer}
              type="video/mp4"/>
            你的浏览器不支持 html5 video播放
          </video>
        </div>
      </div>
    );
  }
}
export default VideoBox;
